<template lang='pug'>
div(class="wrapper")
  notifications
  side-bar
    template(slot="links")
      sidebar-item(:link="{name: 'Dashboard', path: '/dashboard', icon: 'ni ni-tv-2 text-primary'}")
      sidebar-item(:link="{name: 'VendingMachines', path: '/vms', icon: 'ni ni-shop text-primary'}")
      sidebar-item(:link="{name: 'Orders', path: '/orders', icon: 'ni ni-shop text-primary'}")
      sidebar-item(:link="{name: 'User Profile', path: '/profile', icon: 'ni ni-single-02 text-yellow'}")
      sidebar-item(v-if='!$auth.token()' :link="{name: 'Login', path: '/login', icon: 'ni ni-key-25 text-info'}")
      sidebar-item(v-if='$auth.token()' :link="{name: 'Logout', path: '/logout', icon: 'ni ni-circle-08 text-pink' }")

  div(class="main-content")
    dashboard-navbar(:type="$route.meta.navbarType")

    div(@click="$sidebar.displaySidebar(false)")
      fade-transition(:duration="200" origin="center top" mode="out-in")
        router-view
    content-footer(v-if="!$route.meta.hideFooter")

</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      DashboardContent,
      FadeTransition
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
</style>
