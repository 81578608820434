import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';

import NotFound from '@/views/NotFoundPage.vue';
import Vue from 'vue'

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    meta: {
      auth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue')
      },
      {
        path: '/vms',
        name: 'vms',
        component: () => import('../views/VendingMachines.vue')
      },
      {
        path: '/orders',
        name: 'orders',
        component: () => import('../views/Orders.vue')
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/UserProfile.vue'),
        beforeEnter: async(to, from, next) => {
          if(!Vue.$auth.user()) {
            await Vue.$auth.fetchUser()
          }
          next()
        }

      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Login.vue')
      },
      {
        path: '/logout',
        name: 'logout',
        beforeEnter: (to, from, next) => {
          Vue.$auth.logout()
          next('/login')
          return false
        }
      },
      { path: '*', component: NotFound }
    ]
  }
];

export default routes;
