/*!

=========================================================
* BootstrapVue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import router from './routes/router'
import store from './store/store'
import VueAuth from '@d0whc3r/vue-auth-plugin'
import axios from 'axios'
import VueAxios from 'vue-axios'
import iframeResize from 'iframe-resizer/js/iframeResizer'

const options = {
  authMeta: 'auth',
  rolesVar: 'roles',
  tokenDefaultName: 'auth_token',
  userDefaultName: 'auth_user',
  tokenStore: ['localStorage'],
  headerTokenReplace: '{auth_token}',
  tokenType: 'Bearer',

  authRedirect: '/login',

  loginData: {
    url: '/auth/',
    method: 'POST',
    redirect: '/dashboard',
    headerToken: 'Authorization',
    fetchUser: false,
    customToken: (response) => {
      if(response.data['refresh']) localStorage.setItem('refresh_token', response.data['refresh'])
      return response.data['access']
    }
  },
  registerData: '',
  logoutData: {
    url: '/auth/logout',
    method: 'POST',
    redirect: '/login',
    makeRequest: false,
  },
  fetchItem: '',
  // fetchData: '',
  fetchData: {
    url: '/partner/',
    method: 'GET',
    enabled: true,
    interval: 5
  },
  refreshData: {
    url: '/auth/refresh/',
    method: 'POST',
    interval: 3,
    enabled: true
  }
}

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

// axios.defaults.headers.common['Authorization'] = 'Bearer {auth_token}'

axios.interceptors.request.use(
  (config) => {
    const token = Vue.$auth.token()
    if(token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    if(config.url == '/auth/refresh/') {
      let refresh = localStorage.getItem('refresh_token')
      config.data = {'refresh': refresh}
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use( async(response) => {
  return response
}, async(error) => {

  const originalRequest = error.config
  let refresh = localStorage.getItem('refresh_token')
  if(originalRequest.url == '/auth/refresh/') {
    Vue.$auth.logout()
    return
  }
  if(error.response.status == 401 && !originalRequest._retry && refresh) {
    await Vue.$auth.refresh()
    if(!Vue.$auth.token()) {
      Vue.$auth.logout()
      return
    }
    originalRequest._retry = true;
    return axios(originalRequest)
  }
  return Promise.reject(error);
})

Vue.use(VueAxios, axios)
Vue.use(DashboardPlugin)
Vue.use(VueAuth, options)

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
})
